import { ref, watch } from "@vue/composition-api";
import store from "@/store";

export default function useCalendarReportHandler(props, emit) {
  // ------------------------------------------------
  // reportLocal
  // ------------------------------------------------

  const reportLocal = ref(
    props.report.value ? JSON.parse(JSON.stringify(props.report.value)) : {}
  );
  const resetReportLocal = () => {
    reportLocal.value = props.report.value
      ? JSON.parse(JSON.stringify(props.report.value))
      : {};
  };
  watch(props.report, () => {
    resetReportLocal();
  });

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ------------------------------------------------
  watch(props.isReportHandlerSidebarActive, (val) => {
    // ? Don't reset event till transition is finished
    if (!val) {
      resetReportLocal();
      store.commit("calendar/SET_SIDEBARREPORT", {
        isReportHandlerSidebarActive: false,
        report: {},
        fields: [],
      });
    }
  });

  const onSubmit = () => {
    const eventData = JSON.parse(JSON.stringify(reportLocal));

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.report.value.extendedProps.report)
      emit("update-report", eventData.value);
    else emit("add-report", eventData.value);

    // Close sidebar
    emit("update:is-event-handler-sidebar-active", false);
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    reportLocal,
    resetReportLocal,

    // UI
    onSubmit,
  };
}
