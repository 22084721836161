var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2 border-top"},[_c('b-row',{staticClass:"mb-1 text-center"},[_c('b-col',[_c('small',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.eventLocal.extendedProps.descricao))])])],1),_c('div',{staticClass:"w-100 d-flex flex-row",class:_vm.eventLocal.extendedProps.status == 2 ||
      _vm.eventLocal.extendedProps.status == 3 ||
      _vm.eventLocal.extendedProps.scheduleType == 'AE'
        ? 'justify-content-center'
        : 'justify-content-between'},[(
        _vm.eventLocal.extendedProps.scheduleType != 'AE' &&
          _vm.eventLocal.extendedProps.status != 2 &&
          _vm.eventLocal.extendedProps.status != 3
      )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":_vm.eventLocal.extendedProps.report ? 'outline-warning' : 'outline-info'},on:{"click":function($event){return _vm.$emit('open-sidebar-report')}}},[_vm._v(" "+_vm._s(_vm.eventLocal.extendedProps.report ? "Editar relatório" : "Criar relatório")+" ")]):_vm._e(),(
        (_vm.eventLocal.extendedProps.scheduleType != 'AE' &&
          _vm.eventLocal.extendedProps.status == 0) ||
          _vm.eventLocal.extendedProps.status == 1
      )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.$emit('missing-event')}}},[_vm._v(" Aluno faltou ")]):_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right",attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$emit('hide')}}},[_vm._v(" Fechar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }