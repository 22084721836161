<template>
  <div class="p-2 border-top">
    <b-row class="mb-1 text-center">
      <b-col>
        <small class="font-weight-bold">{{
          eventLocal.extendedProps.descricao
        }}</small>
      </b-col>
    </b-row>
    <div
      class="w-100 d-flex flex-row"
      :class="
        eventLocal.extendedProps.status == 2 ||
        eventLocal.extendedProps.status == 3 ||
        eventLocal.extendedProps.scheduleType == 'AE'
          ? 'justify-content-center'
          : 'justify-content-between'
      "
    >
      <b-button
        v-if="
          eventLocal.extendedProps.scheduleType != 'AE' &&
            eventLocal.extendedProps.status != 2 &&
            eventLocal.extendedProps.status != 3
        "
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        :variant="
          eventLocal.extendedProps.report ? 'outline-warning' : 'outline-info'
        "
        class="mr-2"
        @click="$emit('open-sidebar-report')"
      >
        {{
          eventLocal.extendedProps.report
            ? "Editar relatório"
            : "Criar relatório"
        }}
      </b-button>
      <b-button
        v-if="
          (eventLocal.extendedProps.scheduleType != 'AE' &&
            eventLocal.extendedProps.status == 0) ||
            eventLocal.extendedProps.status == 1
        "
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-primary"
        @click="$emit('missing-event')"
      >
        Aluno faltou
      </b-button>
      <b-button
        v-else
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        @click="$emit('hide')"
        variant="outline-secondary"
        class="float-right"
      >
        Fechar
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton, BRow, BCol } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BButton,
    BRow,
    BCol,
  },

  directives: {
    Ripple,
  },

  props: {
    eventLocal: {
      type: Object,
      require: true,
    },
  },
};
</script>
