<template>
  <div class="p-2 border-top">
    <div class="w-100 d-flex flex-row justify-content-between">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-success"
        class="mr-2"
        @click.prevent="$emit('submit-form')"
      >
        Salvar
      </b-button>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        @click.prevent="$emit('hide')"
        variant="outline-secondary"
      >
        Fechar
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BButton,
  },

  directives: {
    Ripple,
  },

  props: {
    eventLocal: {
      type: Object,
      require: true,
    },
  },
};
</script>
