<template>
  <div v-if="Object.keys(reportLocal).length > 0">
    <b-sidebar
      id="sidebar-add-new-report"
      sidebar-class="sidebar-lg"
      :visible="isReportHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-report-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">
            {{ reportLocal.extendedProps.report ? "Editar" : "Criar" }}
            Relatório
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Form -->
        <b-form v-if="reportFields.length > 0">
          <div class="px-1 mt-1">
            <b-row
              v-for="(field, reportKey) in reportFields"
              v-bind:key="reportKey"
            >
              <b-col>
                <b-form-group :label="field.campo" :label-for="field.slug">
                  <b-form-input
                    v-if="field.tipo == 'input'"
                    :id="field.slug"
                    v-model="reportData[field.slug].mensagem"
                    type="text"
                    trim
                  />
                  <b-form-textarea
                    v-else-if="field.tipo == 'textarea'"
                    :id="field.slug"
                    v-model="reportData[field.slug].mensagem"
                  />
                  <v-select
                    v-else-if="
                      field.tipo == 'select' &&
                        field.multiple != null &&
                        field.multiple
                    "
                    v-model="reportData[field.slug].array"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="field.options"
                    :reduce="(val) => (val != null ? val : field.valor)"
                    :clearable="true"
                    :multiple="field.multiple"
                    input-id="user-status"
                  />
                  <v-select
                    v-else-if="field.tipo == 'select'"
                    v-model="reportData[field.slug].mensagem"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="field.options"
                    :reduce="(val) => (val != null ? val : field.valor)"
                    :clearable="true"
                    input-id="user-status"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </template>

      <template #footer>
        <sidebar-footer
          @hide="() => $emit('update:is-report-handler-sidebar-active', false)"
          @submit-form="() => onSubmit()"
        />
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BCol,
  BSidebar,
} from "bootstrap-vue";
import store from "@/store";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { toRefs } from "@vue/composition-api";
import SidebarFooter from "./SidebarFooter.vue";
import useCalendarReportHandler from "./useCalendarReportHandler";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BSidebar,
    SidebarFooter,
    vSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isReportHandlerSidebarActive",
    event: "update:is-report-handler-sidebar-active",
  },
  props: {
    isReportHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    report: {
      type: Object,
      required: true,
    },
  },
  computed: {
    reportFields() {
      return store.state.calendar.sideBarReport.fields;
    },
    reportData() {
      const reportData = this.reportLocal.extendedProps.reportData.json;

      for (const field of this.reportFields) {
        if (reportData[field.slug] === undefined) {
          reportData[field.slug] = {
            titulo: field.campo,
            mensagem: field.valor,
          };
        } else {
          if (field.multiple)
            reportData[field.slug]["mensagem"] = reportData[field.slug].array;
        }
      }
      return reportData;
    },
  },
  setup(props, { emit }) {
    const { reportLocal, onSubmit } = useCalendarReportHandler(
      toRefs(props),
      emit
    );

    return {
      reportLocal,
      onSubmit,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
