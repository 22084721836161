<template>
  <div
    class="fullcalendar-nowrap"
    :style="
      view.type != 'listMonth' && appSkin != 'dark'
        ? { color: event.textColor }
        : ''
    "
  >
    {{ event.extendedProps.materia }}
  </div>
</template>
<script>
import store from "@/store";
export default {
  name: "EventExtend",
  props: {
    event: {
      type: Object,
      required: true,
    },
    view: {
      type: Object,
      required: true,
    },
  },

  computed: {
    appSkin() {
      return store.state.appConfig.layout.skin;
    },

    sizeDesktop() {
      const sizeDesktop = window.matchMedia("(min-width: 768px)").matches;
      return sizeDesktop;
    },
  },
};
</script>

<style scoped>
.fullcalendar-nowrap {
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
  color: #000;
}
</style>
